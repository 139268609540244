@import '../../../styles/styles';

.ourProcessTitle {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $bg-ourprocess-title;
  color: $color-ourprocess-title;
  position: relative;
}

.ourProcessTitle:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 15px $bg-ourprocess-title;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}

.ourProcessTitleHeading {
  margin-bottom: 0;
  font-size: 1rem;
}
