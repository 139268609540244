@import '../../styles/styles';

.floatingScroller {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
.scrollerList {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.floatingButton {
  border-radius: 30px;
  height: 30px;
  width: 30px;
  display: flex;

  justify-content: center;
  align-content: center;
  box-sizing: content-box;
  padding: 10px;
  font-size: 1.2rem;
  background-color: $bg-filterButton;
  color: $color-filterButton;
  border: 1px solid $color-border-filterButton;
  transition: 1s all;
}

.floatingButton:hover,
.floatingButton:focus,
.active {
  background-color: $bg-hover-filterButton;
  border-color: $bg-hover-filterButton;
}
