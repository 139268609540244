@import '../../styles/styles';

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 300;
  color: inherit;
  display: flex;
  justify-content: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    margin-bottom: 1rem;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 3.5rem;
      height: 0;
      border-bottom: 1px solid $color-border;
    }
  }
}
