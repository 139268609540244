@import '../../styles/styles';

.footer {
  background-color: $bg-footer;
  color: $color-footer;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.copyRight {
  font-weight: 100;
  font-size: 1rem;
  margin-bottom: 0;
}
