@import '../../styles/styles';

.showCase {
  background-color: $bg-showCase;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: $color-showCase;
}

.showCaseSubtitle {
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 100;
}

.showCaseFilterBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
