@import '../../../styles/styles';

.showCaseBox {
  box-shadow: 0 0 1px $color-showCase-shadow;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  transition: 1s all;
  transform: scale(1, 1);
}

.imgContainer {
  position: relative;
  top: 0;
  transition: 6s all;
  img {
    width: 100%;
    height: auto;
  }
}

.actionBtns {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s all;
  opacity: 0;
  box-shadow: 0 0 5px 3px $color-showCase-hover-shadow inset;

  a, button {
    margin-right: 0.5rem;
    font-size: 0.8rem;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid transparent;
    color: $color-showCase-btn;
    background-color: $bg-showCase-btn;

    &:hover {
      background-color: $bg-showCase-btn;
      border-color: $color-showCase-btn;
    }
  }
}

.lightbox  {
  position: fixed;
  inset: 0;
  background-color: transparent;
  z-index: 1040 !important
}

