.loader {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  margin-top: -70px;
  //   animation-name: fadeInOut;
  //   animation-duration: 800ms;
}

.loaderWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d1d1d;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
