@import '../../../styles/styles';

.serviceCard {
  padding: 2rem;
  //   margin: 2rem;
  background-color: $bg-servicecard;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-left: 2px solid $color-cardBorder;
}

.iconContainer {
  font-size: 2.5rem;
  color: $color-serviceCardIcon;
  margin-bottom: 0.5rem;
}

.title {
  font-weight: 100;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.body {
  font-weight: 100;
  color: $color-cardBody;
}

.infoLink {
  text-transform: uppercase;
  border: 1px solid $color-cardInfoBorder;
  color: $color-cardInfo;
  border-radius: 1rem;
  padding: 0.3rem 1rem;
  font-size: 0.7rem;
  background-color: transparent;
  transition: 1s all;
  text-decoration: none;

  &:hover {
    background-color: $bg-cardInfo;
    text-decoration: none;
    color: $color-cardInfo;
    border-color: $bg-cardInfo;
  }
}
