@import '../../styles/styles';

.navbar-dark {
  .navbar-nav {
    .nav-link {
      color: $color-headerlink;
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
